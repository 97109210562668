<template>
  <!--
    :style="{
      background: `url(/resources/img/fundation_home_img_v2_${
        screenWidth > 1000 ? 'pc_v2' : 'h5_750'
      }.png) center / cover no-repeat`,
    }"
   -->
  <div
    :class="declareBegin ? 'banner declare-banner' : 'banner'"
    :style="{
      background: `url(${backgroundImg}) center / cover no-repeat`,
    }"
  >
    <div
      v-if="pageYOffset > 800"
      class="banner-mask"
    />
    <Swiper
      v-if="banner.length > 0"
      :class="{
        'banner-swiper': true,
        one: banner.length === 1,
      }"
      :modules="modules"
      :autoplay="autoplayOptions"
      :pagination="{
        clickable: true,
      }"
      @swiper="onSwiper"
      @slide-change="onSlideChange"
    >
      <swiper-slide
        v-for="(item, index) in banner"
        :key="index"
        @change="item.link_to ? toLink(item.link_to) : ''"
      >
        <!-- 科探获奖人公布 -->
        <div
          v-if="IsTimeOut === 2"
          class="demo_item"
        >
          <!-- <img
            :src="`/resources/img/fundation_home_img_${screenWidth > 1000 ? 'pc' : 'h5'}.png`"
            alt=""
            width="100%"
            height="100%"
          /> -->
          <div class="static-content">
            <div class="static-title">
              <div>2024年</div>
              <div>科学探索奖</div>
            </div>
            <div class="static-subtitle">获奖人公布</div>
            <a
              :href="generateLink(item.link_to)"
              target="_blank"
              class="static-link static-button"
            >
              查看名单
            </a>
          </div>
        </div>
        <!-- 开始申报封面 -->
        <div
          v-if="IsTimeOut === 1"
          class="demo_item"
        >
          <div
            class="video_mask"
            @click="playVideo(index)"
          />
          <div class="declare-content">
            <div class="declare-slogan">
              <div class="title">面向未来</div>
              <div class="border"></div>
              <div class="title">奖励潜力</div>
              <div class="border"></div>
              <div class="title">鼓励探索</div>
            </div>
          </div>
          <div
            :style="`opacity: ${tipsHide}`"
            :class="props.link[0]?.link ? 'declare-tips link-hover' : 'declare-tips'"
            @click="openLink"
          >
            公告：2025年“科学探索奖”开放申报
          </div>
          <div
            :style="`opacity: ${buttonHide}`"
            class="declare-button"
            @click="toLink('https://www.xplorerprize.org/apply/login.html#/')"
          >
            <div class="title">立即申报</div>
            <div class="t_button_img">
              <!-- <div class="t_button_img_an"> -->
              <img
                src="/resources/img/icon_jiantou.png"
                alt="application"
              />
              <!-- </div> -->
            </div>
          </div>
          <video
            v-show="canPlay"
            :id="'bannerVideo_' + index"
            autoplay
            loop="loop"
            preload="preload"
            muted
            x-webkit-airplay="true"
            airplay="allow"
            webkit-playsinline
            playsinline
            x5-playsinline
            x5-video-player-type="h5"
            x5-video-player-fullscreen="true"
            height="100%"
            style="object-fit: cover !important"
            @canplay="setPlayVideo"
          >
            <source
              :src="`${$CDN}/media/xp_banner.mp4`"
              type="video/mp4"
            />
            您的浏览器不支持该视频格式。
          </video>
        </div>
        <!-- 常规内容 -->
        <div
          v-if="false"
          class="demo_item"
        >
          <div
            class="video_mask"
            @click="playVideo(index)"
          />
          <div
            v-if="screenWidth < 1000"
            class="video-title"
          >
            <!-- eslint-disable-next-line vue/no-v-html prettier/prettier -->
            <span v-html="item.subtitle ? item.subtitle : '推动科学发展<br />增进人类福祉'" />
          </div>
          <div
            v-else
            class="video-title"
          >
            <!-- eslint-disable-next-line vue/no-v-html prettier/prettier -->
            <span v-html="item.title ? item.title : '推动科学发展,增进人类福祉'" />
          </div>
          <video
            v-show="canPlay"
            :id="'bannerVideo_' + index"
            autoplay
            loop="loop"
            preload="preload"
            muted
            x-webkit-airplay="true"
            airplay="allow"
            webkit-playsinline
            playsinline
            x5-playsinline
            x5-video-player-type="h5"
            x5-video-player-fullscreen="true"
            height="100%"
            style="object-fit: cover !important"
            @canplay="setPlayVideo"
          >
            <source
              :src="item.video_url"
              type="video/mp4"
            />
            您的浏览器不支持该视频格式。
          </video>
        </div>
      </swiper-slide>
    </Swiper>
  </div>
</template>
<script setup>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Autoplay } from 'swiper';
import { ref, onMounted, computed } from 'vue';
import { post } from '@/utils/request';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const props = defineProps({
  banner: {
    type: Object,
    defult() {
      return [];
    },
  },
  link: {
    type: Object,
    defult() {
      return [];
    },
  },
  pageYOffset: {
    type: Number,
    defult: 0,
  },
  screenWidth: {
    type: Number,
    defult: 0,
  },
});
const modules = [Autoplay, Pagination];
const canPlay = ref(false);
const declareBegin = ref(true);
const preIndex = ref(0);
const IsTimeOut = ref(0); // 是否到达2025/01/02 09:00:00  0 不显示 1 到达 2 未到达
const backgroundImg = computed(() => {
  // 申报背景
  if (IsTimeOut.value === 1) {
    // PC背景
    if (props.screenWidth > 1000) {
      return '/resources/img/xp_banner.png';
    } else {
      return '/resources/img/xp_banner_h5.png';
    }
  }

  // 名单公布
  if (IsTimeOut.value === 2) {
    // PC背景
    if (props.screenWidth > 1000) {
      return '/resources/img/fundation_home_img_v2_pc_v2.png';
    } else {
      return '/resources/img/fundation_home_img_v2_h5_750.png';
    }
  }
  return '';
});

// 处理申报按钮渐隐
const buttonHide = computed(() => {
  // 超出高度隐藏
  if (props.pageYOffset > 190) {
    return 0;
  }
  if (props.pageYOffset > 180) {
    return 0.25;
  }
  if (props.pageYOffset > 170) {
    return 0.5;
  }
  if (props.pageYOffset > 160) {
    return 0.75;
  }
  if (props.pageYOffset > 150) {
    return 1;
  }
  return 1;
});

// 处理提示语渐隐
const tipsHide = computed(() => {
  // 超出高度隐藏
  if (props.pageYOffset > 400) {
    return 0;
  }
  if (props.pageYOffset > 390) {
    return 0.25;
  }
  if (props.pageYOffset > 380) {
    return 0.5;
  }
  if (props.pageYOffset > 370) {
    return 0.75;
  }
  if (props.pageYOffset > 360) {
    return 1;
  }
  return 1;
});

// eslint-disable-next-line no-undef
const onSwiper = (swiper) => {
  // console.log(swiper);
};
const onSlideChange = (swiper) => {
  const cur = swiper.activeIndex;
  // curIndex = cur;
  if (props.banner[cur] && props.banner[cur].type === 'video') {
    playVideo(cur);
  } else if (props.banner[preIndex.value].type === 'video') {
    stopVideo(preIndex.value);
  }
  preIndex.value = cur;
};

// 自动轮播的配置
const autoplayOptions = {
  delay: 10000,
  loop: true,
  disableOnInteraction: false, // 设置为false，自动播放将不会在用户交互(滑动)后被禁用，每次交互后都会重新启动
};
const playVideo = (index) => {
  const myVideo = document.getElementById(`bannerVideo_${index}`);
  if (myVideo) myVideo.play();
};
const stopVideo = (index) => {
  const myVideo = document.getElementById(`bannerVideo_${index}`);
  if (myVideo) {
    myVideo.pause();
  }
};
const setPlayVideo = () => {
  setTimeout(() => {
    canPlay.value = true;
  }, 200);
};
const toLink = (url) => {
  window.open(url);
};
const generateLink = (link) => {
  if (link && link !== '') return link;
  return '/';
};
const openLink = () => {
  if (!props.link[0].link) return;
  window.open(props.link[0].link);
};
// const setPlayVideo = () => {
//   setTimeout(() => {
//     canPlay = true;
//   }, 200);
// };
onMounted(() => {
  // 2025/01/02 09:00:00  时间戳  1735779600000
  const time = localStorage.getItem('AaJ0926wnmXQIvoM') || 1735779600000;
  post('user-api/userApi/api/common/currentTime', { time })
    .then((res) => {
      // 正常返回true
      if (res.Response?.Data?.timeOut) {
        IsTimeOut.value = 1;
        document.body.classList.add('declare');
        document.body.classList.remove('award-ceremony');
        localStorage.setItem('IsDeclaring', true);
        console.log('data--res--正常返回true:', res, props.link);
      } else if (res.Response?.Data && !res.Response?.Data?.timeOut && res.Response?.Data?.currentTime) {
        // 正常返回false
        IsTimeOut.value = 2;
        document.body.classList.remove('declare');
        document.body.classList.add('award-ceremony');
        console.log('data--res--正常返回false:', res, props.link);
      } else if (localStorage.getItem('IsDeclaring') || (props?.link && props?.link[0]?.link)) {
        // 异常返回判断是否配置链接
        IsTimeOut.value = 1;
        document.body.classList.add('declare');
        document.body.classList.remove('award-ceremony');
        console.log('data--res--异常返回判断是否配置链接:', res, props.link);
      } else {
        // 异常返回兜底逻辑
        IsTimeOut.value = 2;
        document.body.classList.remove('declare');
        document.body.classList.add('award-ceremony');
        console.log('data--res--异常返回兜底逻辑:', res, props.link);
      }
    })
    .catch((err) => {
      console.log('data--err:', err, props.link);
      // 判断是否有链接配置
      if (localStorage.getItem('IsDeclaring') || (props?.link && props?.link[0]?.link)) {
        IsTimeOut.value = 1;
        document.body.classList.add('declare');
        document.body.classList.remove('award-ceremony');
        console.log('data--res--判断是否有链接配置:', err, props.link);
      } else {
        IsTimeOut.value = 2;
        document.body.classList.remove('declare');
        document.body.classList.add('award-ceremony');
        console.log('data--res--兜底操作:', err, props.link);
      }
    });
});
</script>
<style lang="scss" scoped>
.static-content {
  width: 100%;
  padding-left: 80px;
  box-sizing: border-box;
  position: absolute;
  top: 310px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  z-index: 10;
}

.static-title {
  color: #000000;
  font-size: 48px;
  font-weight: 500;
  letter-spacing: 25px;
  font-family: 'PingFang SC';
  line-height: normal;
  display: flex;

  & div:first-child {
    padding-right: 32px;
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 4px;
      height: 71%;
      background: black;
      position: absolute;
      top: 53%;
      right: 22px;
      transform: translateY(-50%);
    }
  }
}

.static-subtitle {
  padding-top: 8px;
  color: #000000;
  font-size: 108px;
  line-height: normal;
  font-weight: 600;
  font-family: 'PingFang SC';
  letter-spacing: 30.24px;
}

.static-button {
  width: 328px;
  height: 74px;
  line-height: 74px;
  text-align: center;
  background: #3278bc;
  color: #ffffff;
  font-size: 32px;
  font-weight: 500;
  font-family: 'PingFang SC';
  text-align: center;
  border: none;
  cursor: pointer;
}

.static-link {
  display: block;
  margin-top: 80px;

  &:active {
    text-decoration: none;
  }
}

.banner-mask {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  z-index: 11;
}
.banner-swiper {
  color: #eee;
  width: 100%;
  min-height: 1001px;
  :deep(.t-swiper__wrap) {
    color: #eee;
    .t-swiper__navigation-bars {
      color: #ccc;
      .t-swiper__navigation-item {
        width: 100px;
        padding: 38px 0;
        &:not(:nth-child(1)) {
          margin-left: 16px;
        }
        span {
          background: #dcdcdc;
          height: 5px;
        }
        &.t-is-active span {
          background: #3278bc;
        }
      }
    }
  }
  &.one {
    :deep(.t-swiper__wrap) {
      .t-swiper__navigation-bars {
        display: none;
      }
    }
  }
}
.t-button {
  border-radius: 0px !important;
}

.banner {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 1001px;
  position: fixed;
  top: 0;
  :deep(.swiper-pagination) {
    bottom: 37px !important;
    .swiper-pagination-bullet {
      width: 100px;
      height: 5px;
      border-radius: 0;
      background: #dcdcdc;
      opacity: 1;
      margin: 0 8px;
      &.swiper-pagination-bullet-active {
        background: #3278bc;
      }
    }
  }

  .t-button--variant-base.t-button--theme-primary {
    background: transparent !important;
  }

  .t_button_img {
    width: 40px;
    height: 40px;
    // margin-left: 24px;
    display: flex;
    position: relative;
    overflow: hidden;

    img {
      width: 40px;
      height: 40px;
    }

    .t_button_img_an {
      position: absolute;
      left: 0;
      top: 0;
      width: 0%;
      overflow: hidden;
      animation: arrow 5s linear infinite;
    }

    @keyframes arrow {
      0% {
        width: 0%;
      }

      10%,
      15% {
        width: 100%;
        transition: all 1s;
      }

      16%,
      60% {
        left: 0;
        width: 100%;
      }

      61%,
      100% {
        left: 40px;
        width: 0%;
        transition: all 3s;
      }
    }
  }

  .t_buttons {
    position: absolute;
    left: 50%;
    bottom: 90px;
    border: 2px solid #ffffff;
    transform: translate(-50%, -50%);
    width: 404px;
    height: 88px;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 80px 0 100px;

    .t_b_content {
      color: rgba(255, 255, 255, 1);
      font-size: 40px;
      font-weight: 500;
      font-family: $bsa-font-pingfang-sc;
      text-align: left;
      white-space: nowrap;
      line-height: 72px;
    }
  }
  .t_buttons_en {
    padding: 0 28px 0 100px;
  }

  .content_tw {
    font-size: 80px;
    font-weight: 500;
    color: #fff;
    position: absolute;
    bottom: 374px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    text-align: left;
    width: 100%;

    .view_content {
      width: 100%;
      height: 120px;
      overflow: hidden;
      font-family: $bsa-font-pingfang-sc;

      p {
        width: 100%;
        text-align: center;
        position: absolute;
        opacity: 0;

        span {
          white-space: nowrap;

          i {
            font-style: normal;
          }
        }
      }
    }
  }

  .view_content p:nth-child(1) {
    animation: anim1 15s linear infinite;
  }

  .view_content p:nth-child(2) {
    animation: anim2 15s linear infinite;
  }

  .view_content p:nth-child(3) {
    animation: anim3 15s linear infinite;
  }

  @keyframes anim1 {
    0%,
    1% {
      opacity: 0;
    }

    2%,
    10% {
      opacity: 1;
      transition: opacity 3s;
    }

    11%,
    25% {
      opacity: 1;
    }

    26%,
    30% {
      opacity: 0;
      transition: opacity 3s;
    }

    31%,
    100% {
      opacity: 0;
    }
  }

  @keyframes anim2 {
    0%,
    30% {
      opacity: 0;
    }

    31%,
    40% {
      opacity: 1;
      transition: opacity 3s;
    }

    41%,
    55% {
      opacity: 1;
    }

    56%,
    60% {
      opacity: 0;
      transition: opacity 3s;
    }

    61%,
    100% {
      opacity: 0;
    }
  }

  @keyframes anim3 {
    0%,
    60% {
      opacity: 0;
    }

    61%,
    70% {
      opacity: 1;
      transition: opacity 3s;
    }

    71%,
    85% {
      opacity: 1;
    }

    86%,
    95% {
      opacity: 0;
      transition: opacity 3s;
    }
  }

  video {
    width: 100%;
    height: 100%;
  }

  .s_learnMore {
    font-family: $bsa-font-pingfang-sc;
    font-weight: $bsa-font-weight-regular;
    color: #3278bc;
    font-size: 24px;
    text-align: center;
  }

  .learnMore_content {
    cursor: pointer;
  }

  .learnMore {
    margin: 80px 0;

    .p_en {
      color: rgba(26, 26, 26, 1);
      font-size: 40px;
      font-weight: 400;
      font-family: $bsa-font-pingfang-sc;
      text-align: justify;
      line-height: 72px;
    }

    .p_us {
      color: rgba(26, 26, 26, 1);
      font-size: 40px;
      font-weight: 400;
      text-align: left;
      line-height: 80px;
      font-family: $bsa-font-pingfang-sc;
      letter-spacing: 0.1px;
    }
  }

  .w {
    width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
}

// swiper list
.demo_item {
  position: relative;
  width: 100%;
  height: 1001px;
  z-index: 0;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
  .video_mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    // background: rgba(0, 0, 0, 0.4);
  }
  .video-title {
    color: #ffffffe6;
    font-size: 80px;
    font-weight: 500;
    font-family: 'PingFang SC';
    text-align: left;
    line-height: 80px;
    position: absolute;
    right: 0;
    bottom: 375px;
    text-align: center;
    left: 0;
    transform: translate(0, -50%);
  }
  .declare-content {
    position: absolute;
    left: 50%;
    top: 328px;
    text-align: center;
    transform: translate(-50%, 0);
    // z-index: 99;
    .declare-slogan {
      display: flex;
      align-items: center;
      color: #ffffff;
      text-align: center;
      font-size: 80px;
      font-style: normal;
      font-weight: 600;
      line-height: 80px;
      word-break: keep-all;
      font-family: 'PingFang SC';
      .border {
        width: 6px;
        height: 79px;
        background-color: #fff;
        margin: 0 45px;
      }
    }
  }
  .declare-tips {
    position: absolute;
    left: 50%;
    top: 456px;
    text-align: center;
    transform: translate(-50%, 0);
    color: #ffffff;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    font-family: 'PingFang SC';
  }
  .link-hover {
    z-index: 99;
  }
  .link-hover:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-offset: 22%;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .declare-button {
    padding: 8px 79px 7px 100px;
    border: 2px solid #fff;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 370px;
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 646px;
    text-align: center;
    transform: translate(-50%, 0);
    z-index: 99;
    .title {
      color: #ffffff;
      text-align: center;
      font-family: 'PingFang SC';
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 56px;
    }

    .t_button_img {
      position: relative;
      display: flex;
      width: 34px;
      height: 16px;
      overflow: hidden;

      img {
        width: 34px;
        height: 16px;
      }

      // .t_button_img_an {
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 0%;
      //   overflow: hidden;
      //   animation: arrow 5s linear infinite;
      // }

      // @keyframes arrow {
      //   0% {
      //     width: 0%;
      //   }

      //   10%,
      //   15% {
      //     width: 100%;
      //     transition: all 1s;
      //   }

      //   16%,
      //   60% {
      //     left: 0;
      //     width: 100%;
      //   }

      //   61%,
      //   100% {
      //     left: 34px;
      //     width: 0%;
      //     transition: all 3s;
      //   }
      // }
    }
  }
}

.titles {
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-size: 50px;
  font-weight: 700;
  font-family: $bsa-font-pingfang-sc;
  text-align: left;
  white-space: nowrap;
}

.banner_butt {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);

  .t-button__text {
    font-size: 45px;
  }

  .t-button--theme-primary {
    width: 325px;
    height: 77px;
    line-height: 77px;
    font-size: 20px;
    background: #3278bc;
    border: 1px solid #3278bc;
  }
}
@media (max-width: 500px) {
  .banner {
    height: 450px;
    position: static;
  }
  .banner-swiper {
    min-height: 450px;
  }
  .static-content {
    top: 271px;
  }
  .declare-banner {
    height: 500px;
    .banner-swiper {
      min-height: 500px;
    }
  }
}
@media (max-width: 999px) and (min-width: 501px) {
  .banner {
    height: 580px;
    position: static;
  }
  .banner-swiper {
    min-height: 580px;
  }
  .static-content {
    top: 351px;
  }
}

@media (max-width: 1440px) and (min-width: 1000px) {
  .demo_item {
    .declare-content {
      .declare-slogan {
        font-size: 64px;
        line-height: 64px;
        .border {
          width: 4px;
          height: 64px;
          margin: 0 35px;
        }
      }
    }
    .declare-tips {
      font-size: 32px;
      line-height: 44px;
      white-space: nowrap;
    }
  }
}

// 移动端
@media (max-width: 999.9px) {
  .static-content {
    padding-left: 0;
    align-items: center;
  }

  .static-title {
    color: #332005;
    font-family: 'PingFang SC';
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 8.18px;
    transform: translateX(3.09px);

    & div:first-child {
      padding-right: 16px;
      position: relative;
      &::after {
        content: '';
        display: block;
        width: 2px;
        position: absolute;
        right: 10px;
      }
    }
  }

  .static-subtitle {
    padding-top: 0;
    color: #332005;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 11.2px;
    transform: translateX(5.6px);
  }

  .static-button {
    width: 180px;
    height: 44px;
    line-height: 44px;
    background: #3278bc;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    font-family: 'PingFang SC';
    text-align: center;
    border: none;
    cursor: pointer;
  }

  .static-link {
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }

  // .banner {
  //   height: 450px;
  //   position: static;
  // }
  // .banner-swiper {
  //   min-height: 450px;
  // }
  .demo_item {
    height: 100%;
    .video-title {
      font-size: 40px;
      bottom: 170px;
      line-height: 55px;
    }
    .video_mask {
      z-index: 1;
    }
    .declare-content {
      top: 88px;
      width: 100%;
      .declare-slogan {
        display: block;
        font-size: 32px;
        line-height: 36px;
        .border {
          display: none;
        }
        .title {
          margin-bottom: 22.5px;
        }
      }
    }
    .declare-tips {
      top: 293px;
      font-size: 18px;
      line-height: 28px;
      word-break: keep-all;
      width: 100%;
    }
    .link-hover {
      text-decoration: underline;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-underline-offset: 22%;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
    .declare-button {
      display: none;
      // padding: 3px 30px 4px 52px;
      // border: 1px solid #fff;
      // width: 185px;
      // align-items: normal;
      // top: auto;
      // bottom: 90px;
      // .title {
      //   font-size: 14px;
      //   line-height: 28px;
      // }

      // .t_button_img {
      //   margin-top: 3px;
      //   // width: 17px;
      //   // height: 8px;
      //   overflow: hidden;
      //   margin-left: 2px;

      //   img {
      //     width: 17px;
      //     height: 8px;
      //   }

      //   .t_button_img_an {
      //     animation: arrow-mobile 5s linear infinite;
      //   }

      //   @keyframes arrow-mobile {
      //     0% {
      //       width: 0%;
      //     }

      //     10%,
      //     15% {
      //       width: 100%;
      //       transition: all 1s;
      //     }

      //     16%,
      //     60% {
      //       left: 0;
      //       width: 100%;
      //     }

      //     61%,
      //     100% {
      //       left: 17px;
      //       width: 0%;
      //       transition: all 3s;
      //     }
      //   }
      // }
    }
  }
}
</style>
