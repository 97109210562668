<template>
  <div class="home">
    <swiper
      :banner="banner"
      :link="link"
      :page-y-offset="pageYOffset"
      :screen-width="screenWidth"
    />
    <div class="linecolor">
      <div class="home-block about">
        <div class="title">{{ $t('aboutProject') }}</div>
        <div
          v-if="aboutFoundationDesc"
          class="block-content"
        >
          {{ aboutFoundationDesc.desc }}
        </div>
      </div>
      <div class="home-block">
        <div class="title">{{ $t('menuListCommonwealProject') }}</div>
        <div
          v-if="screenWidth >= 1000"
          class="block-content-list"
        >
          <div
            v-for="(item, idx) in projectList"
            :key="idx"
            class="item"
            @mouseenter="mouseenter(idx)"
            @mouseleave="mouseleave(idx)"
          >
            <img
              :src="item.logo"
              class="item-bg"
            />
            <div class="item-block">
              <img :src="item.logobg" />
            </div>
            <div
              class="item-over"
              :style="
                item.backgroundimg
                  ? 'background: ' + item.background + ' url(' + item.backgroundimg + ') no-repeat center/cover'
                  : 'background: ' + item.background
              "
            >
              <div
                class="item-over-name"
                :style="'color:' + item.color"
              >
                {{ item.name }}
              </div>
              <div
                class="item-over-line"
                :style="`background: ${item.lineColor}`"
              />
              <div
                class="desc"
                :style="`color:${item.color}`"
              >
                <span
                  class="item-over-title"
                  :style="'color:' + item.titlecolor"
                  >{{ item.title }}</span
                >{{ item.content }}
              </div>
              <div class="getMore">
                <span
                  :style="'color:' + item.color"
                  @click="linkTo(item.url)"
                  >{{ $t('homeMore') }}</span
                >
                <div
                  class="getMore-line"
                  style="background: rgba(242, 242, 242, 0.3)"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="mobile-content-list"
        >
          <div
            v-for="(item, idx) in projectList"
            :key="idx"
            class="item"
            :style="'background: ' + item.mobileBackground"
          >
            <productSwiper :banner="item.banner2 ? [item.logo, item.banner2] : [item.logo]" />
            <div class="item-over">
              <div
                class="item-over-name"
                :style="'color:' + item.mobileColor"
              >
                {{ item.name }}
              </div>
              <div
                class="item-over-line"
                :style="`background: ${item.mobileLineColor}`"
              />
              <div
                class="desc"
                :style="`color:${item.mobileColor}`"
              >
                <span
                  class="item-over-title"
                  :style="'color:' + item.mobileColor"
                  >{{ item.title }}</span
                >{{ item.content }}
              </div>
              <div class="getMore">
                <span
                  :style="'color:' + item.mobileColor"
                  @click="linkTo(item.url)"
                  >了解更多</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="newsList && newsList.length > 0"
        class="home-block news"
      >
        <img
          src="~@/assets/imgs/bg_news.png"
          class="bg-news"
        />
        <div class="title">{{ $t('newsAndTrends') }}</div>
        <div class="news-list">
          <div class="item">
            <div
              class="left-content"
              @click="linkTo(newsList[0].jumpUrl)"
            >
              <img
                :src="newsList[0].img"
                class="left-image"
              />
            </div>
            <div class="right-content">
              <div
                class="news-title"
                @click="linkTo(newsList[0].jumpUrl)"
              >
                {{ newsList[0].title }}
              </div>
              <div
                class="news-content"
                @click="linkTo(newsList[0].jumpUrl)"
              >
                {{ screenWidth >= 1000 ? newsList[0].introText : newsList[0].time }}
              </div>
              <div class="getMore">
                <span @click="JumpRouter('/news')">{{ screenWidth >= 1000 ? $t('homeMore') : '了解更多' }}</span>
                <div class="getMore-line" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import swiper from './components/swiper/index.vue';
import productSwiper from './components/swiper/product.vue';
import useFetchData from '@/hooks/useFetchData.js';
const store = useStore();
const { currentRoute } = useRoute();
const { push } = useRouter();
const hoverProductIdx = ref(-1);
useFetchData('fetchHome', 'home');
const screenWidth = ref(window.innerWidth || document.documentElement.clientWidth);
const pageYOffset = ref(0);
const banner = computed(() => {
  const list = store.state.home.banner;
  return list;
});
const link = computed(() => {
  const list = store.state.home.link;
  return list;
});
const handleScroll = () => {
  screenWidth.value = window.innerWidth || document.documentElement.clientWidth;
  pageYOffset.value = window.pageYOffset;
};
onMounted(() => {
  window.addEventListener('resize', handleScroll, true);
  document.addEventListener('scroll', handleScroll, true);
});
onUnmounted(() => {
  window.removeEventListener('resize', handleScroll, true);
  document.removeEventListener('scroll', handleScroll, true);
});

const aboutFoundationDesc = computed(() => store.state.home.foundation);
const projectList = computed(() => store.state.home.projectsList);
const newsList = computed(() => store.state.home.newsList);
const JumpRouter = (path) => {
  if (currentRoute !== path) {
    push(path);
  } else {
    window.location.reload();
  }
};
const linkTo = (url) => {
  window.open(url);
};
const mouseenter = (idx) => {
  hoverProductIdx.value = idx;
};
const mouseleave = () => {
  hoverProductIdx.value = -1;
};
</script>
<style lang="scss" scoped>
.linecolor {
  background: linear-gradient(180deg, #ffffff00 0%, #ffffffa1 14.000000000000002%, #ffffffff 26%, #ffffffff 100%);
  margin-top: 875px;
  z-index: 1;
  position: relative;
  padding-top: 100px;
}
.about {
  background: #ffffff;
  padding: 140px 120px 0;
  box-sizing: border-box;
  .block-content {
    text-align: justify !important;
  }
}
.news-list {
  .item {
    display: flex;
    justify-content: space-between;
    .left-content {
      height: 330px;
      .left-image {
        width: 776px;
        height: 330px;
        object-fit: cover;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
      }
    }
    .right-content {
      width: 776px;
      color: rgba(0, 0, 0, 0.9);
      position: relative;
      .news-title {
        font-size: 32px;
        font-weight: 500;
        font-family: 'PingFang SC';
        line-height: 56px;
        margin-bottom: 16px;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        @include line-ellipsis(2);
        &:hover {
          font-weight: 700;
        }
      }
      .news-content {
        font-size: 28px;
        font-weight: 400;
        font-family: 'PingFang SC';
        text-align: left;
        line-height: 56px;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        @include line-ellipsis(2);
      }
      .getMore {
        margin-top: 8px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
}
.home-block {
  margin: 50px auto;
  // @include w12();
  width: 1600px;
  &.news {
    margin-bottom: 0;
    padding-bottom: 200px;
    position: relative;
    .bg-news {
      position: absolute;
      right: 0;
      height: 100%;
      top: 0;
      bottom: 0;
      z-index: -2;
    }
  }
  .title {
    opacity: 1;
    color: #343434;
    font-size: 40px;
    font-weight: 600;
    font-family: 'PingFang SC';
    text-align: center;
    line-height: 44px;
    position: relative;
    margin-bottom: 92px;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 72px;
      left: calc(50% - 20px);
      width: 40px;
      height: 4px;
      background: #a59178;
    }
    &.about {
      margin-bottom: 68px;
    }
  }

  .block-content {
    color: #000000;
    font-size: 32px;
    font-weight: 400;
    font-family: 'PingFang SC';
    text-align: left;
    line-height: 56px;
  }
  .block-content-list {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .item {
      width: 800px;
      height: 680px;
      position: relative;
      .item-bg {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        height: inherit;
      }
      .item-block {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9;
        transition: 0.5s;
        opacity: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 20;
        }
      }
      .item-over {
        opacity: 0;
        z-index: -2;
        background: #ffffff;
        width: 100%;
        height: 100%;
        color: rgba(0, 0, 0, 0.6);
        font-size: 28px;
        font-weight: 400;
        font-family: 'PingFang SC';
        line-height: 56px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translatex(-100%);
        padding: 64px 56px;
        transition: 0.5s;
        // transition-delay: 0.1s;
        box-sizing: border-box;
        &-name {
          color: #000000e6;
          font-size: 40px;
          font-weight: 500;
          line-height: 56px;
        }
        &-title {
          font-weight: 500;
          color: rgba(0, 0, 0, 0.9);
        }
        &-line {
          margin: 16px 0;
          height: 2px;
          opacity: 1;
          background: #ddddddff;
        }
        .desc {
          @include line-ellipsis(6);
        }
        .getMore {
          position: absolute;
          bottom: 64px;
          left: auto;
          right: 56px;
          text-align: center;
        }
      }
      &:nth-child(1) {
        .item-over {
          transform: translatex(calc(100%));
          left: auto;
          right: 0;
        }
        .item-block-over {
          right: auto;
          left: 0;
        }
        &:hover {
          .item-over {
            left: auto;
            right: 100px;
          }
        }
      }
      &:hover {
        // transition: 0.3s;
        .item-over {
          opacity: 1;
          z-index: 23;
          transition: 0.5s;
          transition: opacity 0.3s, width 0.5s, left 0.5, right 0.5s;
          width: calc(100% + 100px);
          left: 100px;
          right: 0;
          &:nth-child(1) {
            left: 0;
            right: 100px;
          }
        }
        .item-block {
          transform: 0.5s;
          opacity: 1;
          width: 700px;
        }
        &:nth-child(2) {
          .item-block {
            margin-left: 100px;
            margin-right: 0;
          }
        }
      }
    }
  }
  .getMore {
    // background: url('@/assets/imgs/home_more.png') no-repeat right center/40px;
    // padding-right: 72px;
    color: rgba(0, 0, 0, 0.9);
    text-align: right;
    margin-top: 30px;
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
    position: relative;
    span {
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
    &-line {
      width: 114px;
      height: 4px;
      background: #f2f2f2;
      margin: 14px 0 0 auto;
      position: relative;
      &::after {
        content: '';
        display: block;
        height: 4px;
        width: 0;
        position: absolute;
        background: #3278bcff;
      }
      &::before {
        content: '';
        display: block;
        height: 4px;
        width: 0;
        position: absolute;
        background: #a69178;
      }
    }
    span:hover {
      font-weight: 500;
    }
    span:hover + .getMore-line {
      // background: #a69178;
      &::after {
        width: 75%;
        transition: 0.3s;
        transition-delay: 0.1s;
      }
      &::before {
        width: 100%;
        transition: 0.4s;
      }
    }
  }
}
@media (max-width: 1680px) and (min-width: 1000px) {
  .home-block {
    width: 1200px;
    .block-content-list {
      .item {
        width: 50%;
        height: 600px;
        .item-block {
          width: 100%;
        }
        // &:nth-child(1) {
        //   .item-over {
        //     left: auto;
        //     right: 20% !important;
        //   }
        // }
        &:hover {
          .item-block {
            width: 80%;
            margin-right: 20%;
          }
          .item-over {
            width: 120%;
            left: 20%;
            right: 0;
          }
          &:nth-child(1) {
            .item-over {
              left: auto;
              right: 20% !important;
            }
          }
          &:nth-child(2) {
            .item-block {
              margin-left: 20%;
              margin-right: 0;
            }
          }
        }
      }
    }
    .news-list {
      .item {
        .left-content {
          height: 242px;
          .left-image {
            width: 570px;
            height: 242px;
          }
        }
        .right-content {
          width: calc(100% - 570px - 54px);
          .getMore {
            position: relative;
            margin-top: 18px;
          }
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  .home-block {
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    &.about {
      padding-top: 140px;
    }
    .block-content-list {
      .item .item-over {
        &-name {
          font-size: 32px;
        }
        .getMore {
          bottom: 48px;
        }
      }
    }
  }
}
@media (max-width: 999.9px) {
  .linecolor {
    margin-top: 0px;
    padding-top: 0;
    background: linear-gradient(180deg, #ffffff00 0%, #ffffffa1 9%, #ffffffff 13%, #ffffffff 100%);
    .about {
      margin: 0 20px;
      width: calc(100% - 40px);
      box-shadow: 0 8px 12px 1px #0000000a;
      padding-top: 40px;
      background: #ffffff;
    }
  }
  .home-block {
    width: 100%;
    padding: 40px 16px;
    box-sizing: border-box;
    background: #f7f8f9;
    margin: 0;
    &:nth-child(2n) {
      background: #ffffff;
    }
    .title {
      font-size: 20px;
      line-height: 32px;
      color: #404040;
      font-weight: 500px;
      margin-bottom: 36px;
      &.ismobile {
        display: none;
      }
      &::after {
        top: 46px;
        height: 2px;
        width: 28px;
      }
    }
    .block-content {
      font-size: 18px;
      line-height: 36px;
    }
    .block-content-list {
      display: block;
      .item {
        width: 100%;
        height: auto;
        margin-bottom: 40px;
        .item-block {
          height: 200px;
          img {
            height: 45px;
          }
        }
        .item-over {
          opacity: 1;
          z-index: 0;
          position: relative;
          transform: translateX(0) !important;
          padding: 0 !important;
          background: transparent;
          &-name {
            color: #1a1a1a;
            font-size: 20px;
            line-height: 36px;
            margin-top: 20px;
          }
          &-title {
            font-weight: 500;
            color: #1a1a1a;
          }
          &-line {
            margin: 16px 0;
            height: 1px;
            opacity: 1;
            background: #ddddddff;
          }
          .desc {
            @include line-ellipsis(6);
            text-align: justify;
            font-size: 18px;
            line-height: 36px;
          }
        }
      }
    }
    &.news {
      padding-bottom: 40px;
    }
  }
  .news-list {
    .item {
      display: block;
      .left-content {
        height: auto;
        .left-image {
          width: 100%;
          min-height: 146px;
          height: calc((100vw - 36px) * 0.4256);
        }
      }
      .right-content {
        width: 100%;
        .news-title {
          color: #1a1a1aff;
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 0;
          line-height: 36px;
          &:hover {
            font-weight: 500;
          }
        }
        .news-content {
          color: #00000066;
          font-size: 16px;
          font-weight: 400;
          font-family: 'PingFang SC';
          text-align: left;
          line-height: 25px;
          margin-top: 6px;
        }
        .news-date {
          display: block;
          color: #00000066;
          font-size: 16px;
          line-height: 25px;
        }
        .getMore {
          position: static;
          // display: none;
          margin-bottom: 16px !important;
        }
      }
    }
  }
  .getMore {
    background: url('@/assets/imgs/home_more.png') no-repeat right bottom 3px/24px;
    padding-right: 40px;
    height: 36px;
    margin-top: 12px;
    span {
      line-height: 36px;
      font-size: 18px;
    }
    &-line {
      display: none;
    }
  }
  .mobile-content-list {
    margin: 0 -16px -40px;
    .item {
      padding: 20px 16px;
      &-over {
        margin: 0 -16px -20px;
        padding: 0 16px 20px;
        &-name {
          color: #000000e6;
          font-size: 20px;
          font-weight: 500;
          line-height: 36px;
          padding-top: 30px;
        }
        &-title {
          font-weight: 500;
          color: rgba(0, 0, 0, 0.9);
        }
        &-line {
          margin: 12px 0;
          height: 2px;
          opacity: 1;
          background: #ddddddff;
        }
        .desc {
          font-size: 18px;
          line-height: 36px;
          @include line-ellipsis(100);
        }
      }
    }
  }
}
</style>
