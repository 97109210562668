/* eslint-disable no-param-reassign */
import { createStore } from 'vuex';
import { fetchStatic, flatData, FETCH_LIST } from '@/hooks/hookUtils';
import mockData from '../../mock_data.json';

// 接入云歌后 考虑是否要分module
export default createStore({
  state() {
    return {
      data: mockData,
      fetched: {
        infomation: false,
        home: false,
        about: false,
        newslist: false,
        useragreement: false,
      },
      home: {
        banner: mockData.home_banner,
        projectsList: mockData.projectList,
        foundation: mockData.about,
        newsList: mockData.newslist,
        link: mockData.declare_link,
      },
    };
  },
  getters: {},
  mutations: {
    setData(state, value) {
      state.data = value;
    },
    toggleFetched(state, { key, value }) {
      state.fetched[key] = value;
    },
    setInfomation(state, value) {
      state.data.infomation = value;
    },
    setAbout(state, value) {
      state.data.about = value;
    },
    setNewsList(state, value) {
      state.data.newsList = value;
    },
    setUserAgreement(state, value) {
      state.data.userAgreement = value;
    },
    setHome(state, value) {
      state.home = value;
    },
  },
  actions: {
    async fetchInfomation({ commit }) {
      try {
        const result = await fetchStatic(FETCH_LIST.INFOMATION);
        const { infomation } = flatData(result);
        commit('setInfomation', infomation);
        commit('toggleFetched', { key: 'infomation', value: true });
      } catch (e) {
        commit('setInfomation', mockData.infomation);
        commit('toggleFetched', { key: 'infomation', value: false });
      }
    },
    async fetchAbout({ commit }) {
      const result = await fetchStatic(FETCH_LIST.ABOUT);
      const about = flatData(result);
      commit('setAbout', about);
      commit('toggleFetched', { key: 'about', value: true });
    },
    async fetchNewsList({ commit }) {
      const result = await fetchStatic(FETCH_LIST.NEWLISTS);
      const { newsList } = flatData(result);
      commit('setNewsList', newsList);
      commit('toggleFetched', { key: 'newslist', value: true });
    },
    async fetchUserAgreement({ commit }) {
      const result = await fetchStatic(FETCH_LIST.USERAGREEMENT);
      const {
        useragreement: { userAgreement },
      } = flatData(result);
      commit('setUserAgreement', userAgreement);
      commit('toggleFetched', { key: 'useragreement', value: true });
    },
    async fetchHome({ commit }) {
      const result = await fetchStatic(FETCH_LIST.HOME, true);
      const home = flatData(result);
      home.banner = result.banner;
      home.link = result.declare_link;
      commit('setHome', home);
      commit('toggleFetched', { key: 'home', value: true });
    },
  },
  modules: {},
});
